import { render, staticRenderFns } from "./OpenCartList.vue?vue&type=template&id=6f23b35b&scoped=true&"
import script from "./OpenCartList.vue?vue&type=script&lang=js&"
export * from "./OpenCartList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f23b35b",
  null
  
)

export default component.exports