<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form
          :inline="true"
          :model="searchForm"
          ref="searchForm"
        >
          <el-row>
            <el-col :md="8">
              <el-form-item label="客户货号:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.cust_artno"
                  clearable
                  placeholder="请客户货号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="产品编号:">
                <el-input
                  size="small"
                  v-model="searchForm.prod_no"
                  clearable
                  placeholder="请填写产品编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="成品采购号:" >
                <el-input
                  size="small"
                  v-model="searchForm.podr_no"
                  clearable
                  placeholder="请填写成品采购号"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="*所属账套:">
                <el-select
                    v-model="searchForm.acct_id"
                    filterable
                    placeholder="请选择所属账套"
                    clearable
                    size="small"
                >
                  <el-option
                      v-for="item in acctList"
                      :key="item.acct_id"
                      :label="item.acct_no"
                      :value="item.acct_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="开卡时间:">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="getCpttsNow()"
                  class="vg_ml_16"
                  >查询</el-button
                >
                <el-button
                  type="info"
                  size="small"
                  class="vd_export"
                  @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button
          type="primary"
          size="small"
          @click="addRow()"
          :disabled="!btn.add"
          ><i class="el-icon-plus"></i> 批量开卡</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="addMendRow()"
          :disabled="!btn.add"
          ><i class="el-icon-plus"></i> 补卡</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="doDelete()"
          :disabled="!btn.delete"
          ><i class="el-icon-delete"></i> 删除</el-button
        >
      </div>
      <el-row>
        <el-col :md="24">
          <!--  @row-dblclick="dbClickJp" class="vg_pointer" -->
          <el-table
            v-loading="isLoding"
            ref="multiTable"
            
            :data="tableData"
            @select="selectRows"
            @select-all="selectRows"
           
            border
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="Rfid卡号" prop="rfid_no" />
            <el-table-column label="成品采购号" prop="podr_no" >
              <template slot-scope="scope">
                <span v-if="scope.row.prod_no">{{scope.row.podr_no}}</span>
                <span v-else class="vg_notava">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="客户货号" prop="cust_artno" >
              <template slot-scope="scope">
                <span v-if="scope.row.prod_no">{{scope.row.cust_artno}}</span>
                <span v-else class="vg_notava">暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              label="产品编号"
              prop="prod_no"
              show-overflow-tooltip
            >
            <template slot-scope="scope">
              <span v-if="scope.row.prod_no">{{scope.row.prod_no}}</span>
              <span v-else class="vg_notava">暂无</span>
            </template>
            </el-table-column>
            <el-table-column label="产品数量" prop="podr_prod_num" />
            <el-table-column
              label="开卡人"
              prop="stff_name"
              show-overflow-tooltip
            />
            <!-- <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/> -->
            <el-table-column
              label="开卡时间"
              prop="create_time"
              :formatter="formatDate"
            />
						<el-table-column label="*所属账套" prop="acct_no" align="center" width="120"/>
            <!-- <el-table-column
              label="生产状态"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.rfid_status == 0" type="info"
                  >未经历</el-tag
                >
                <el-tag v-if="scope.row.rfid_status == 1">经历中</el-tag>
                <el-tag v-if="scope.row.rfid_status == 2" type="success"
                  >已完成</el-tag
                >
              </template>
            </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination
            :totalPage="totalPage"
            @changePageSearch="changePageSearch"
            ref="pubPagination"
          ></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { cpttAPI } from "@api/modules/comptitle";
import { acctAPI } from "@api/modules/acct";
import pubPagination from "@/components/common/pubPagination";
import options from "@/views/component/common/options";
import { openAPI } from "@api/modules/open";
export default {
  name: "CpttList",
  components: {
    pubPagination,
  },
  data() {
    return {
      searchForm: {
        rfid_no: "",
        prod_no: "",
        podr_no:'',
        acct_id:'',
        cust_artno:'',
        rfid_status: null,
        timeValue: {
          startTime: null,
          endTime: null,
        },
      },
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      isLoding: true,
      options: [
        {
          value: 0,
          label: "未经历",
        },
        {
          value: 1,
          label: "经历中",
        },
        {
          value: 2,
          label: "已完成",
        },
      ],
      acctList:[],
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === "/open_add" || from.path === "/open_edit") {
        this.initData();
      }
    },
  },
  methods: {
    initData() {
      this.getRfidList();
      this.getAcct()
    },
    //获取卡信息列表
    getRfidList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(openAPI.get_rfids, {
        page_no: this.currentPage,
        rfid_no: this.searchForm.rfid_no,
        acct_id: this.searchForm.acct_id,
        cust_artno: this.searchForm.cust_artno,
        prod_no: this.searchForm.prod_no,
        rfid_status: this.searchForm.rfid_status,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        podr_no:this.searchForm.podr_no
      }).then((res) => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg);
        }
        this.tableData = res.data.data.list;
        this.btn = res.data.data.btn;
        this.totalPage = res.data.data.total;
        setTimeout(() => {
          this.isLoding = false;
        }, 500);
      });
    },
     //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            return ''
          }
          return this.$message.error(res.data.msg);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        rfid_no: "",
        prod_no: "",
        podr_no:'',
        rfid_status: null,
        timeValue: {
          startTime: null,
          endTime: null,
        },
      };
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.isLoding = true;
      this.initData();
    },

    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 查询方法
    getCpttsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.isLoding = true;
      this.getRfidList();
    },

    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.isLoding = true;
      this.getRfidList();
    },

    selectRows(selection) {
      let rfidStatusList = selection.map((item) => {
        return item.rfid_status;
      });
      let allStatus = rfidStatusList.reduce(function (sum, number) {
        return sum + number;
      }, 0);
      if (allStatus > 0) {
        this.btn.delete = false;
      } else {
        this.btn.delete = true;
      }
      this.multiSelection = selection;
    },
    // 新增
    addRow() {
      this.jump("/open_add");
    },
    //补卡
    addMendRow() {
      this.jump("/open_addFill");
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: "warning", message: "请至少选择一条数据！" });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map((item) => {
        ids.push(item.rfid_id);
      });
      post(openAPI.destroy_rfid_by_ids, { rfid_id_list: ids })
        .then((res) => {
          if (res.data.code !== 0) {
            this.initData();
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          this.multiSelection = [];
          this.initData();
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      // const permId = this.$route.query.perm_id
      // this.jump("/open_edit", { rfid_no: row.rfid_id });
    },
  },
};
</script>

<style scoped lang="scss">

</style>